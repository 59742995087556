#home img {
    width: 400px;
    border-radius: 100%;
    border: 5px solid #06063f;
}

#home p {
    font-style: italic;
    text-align: center;
}

#home h1, #home h2 {
    text-align: center;
}

@media only screen and (max-width: 450px) {
    nav div span {
        display: none;
    }

    #home img {
        width: 200px;
    }
}
