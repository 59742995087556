#resume a {
    color: #06063f;
    font-weight: bold;
}

#resume a:hover {
    font-style: italic;
    text-decoration: underline;
}

#resume article {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

#resume p {
    text-align: center;
}

.chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.chart p {
    font-style: italic;
    text-align: center;
}

blockquote {
    border-left: 10px solid #06063f;
    margin: 0;
    padding: 0 10px;
}

.attribution {
    display: block;
    text-align: right;
}

@media only screen and (max-width: 1000px) {
    #resume article {
        flex-direction: column;
    }
}
