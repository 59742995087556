#about img {
    width: 400px;
    border-radius: 100%;
    border: 5px solid #06063f;
}

#about-content {
    display: flex;
    gap: 50px;
}

#about article {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#about ul {
    display: flex;
    flex-direction: column;
    list-style-type: disc;
    padding-left: 40px;
}

#bio {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.favorites {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@media only screen and (max-width: 1000px) {
    #about-content {
        flex-direction: column-reverse;
        gap: 30px;
        align-items: center;
    }

    #about h2 {
        text-align: center;
    }
}

@media only screen and (max-width: 500px) {
    #about img {
        width: 200px;
    }

    #about p {
        text-align: center;
    }

    #about ul {
        padding-left: 10px;
    }
}
